<template>
    <div class="wrapper">
        <CRow>
            <CCol lg="12">
                <CButton
                    @click="$router.go(-1)"
                    size="sm"
                    color="warning" 
                > 
                    <font-awesome-icon icon="arrow-left"/> Go back
                </CButton>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="folder-open"/> Manage Form
                        <CButton 
                            size="sm"
                            color="success" 
                            @click="previewForm()"
                        >
                            <font-awesome-icon icon="eye"/> Preview Form
                        </CButton>
                        <!-- <template v-if="total_fields_with_value <= 0"> -->
                        <template v-if="form.status == 'Active'">
                            <CButton 
                                size="sm"
                                color="danger" 
                                class="float-right" 
                                @click="toggleEdit(), returnInformation()" 
                                v-show="edit"
                            >
                                <font-awesome-icon icon="window-close"/> Cancel
                            </CButton>
                            <CButton 
                                v-if="$store.getters['can']('update-form')"
                                size="sm"
                                color="success" 
                                class="float-right"
                                :disabled="!isFormValid || (detail_field.length <= 0 && form.has_details)" 
                                v-show="edit" 
                                @click="updateForm()"
                            >
                                <font-awesome-icon icon="save"/> Update
                            </CButton>
                            <CButton 
                                v-if="$store.getters['can']('edit-form')"
                                size="sm"
                                color="info" 
                                class="float-right" 
                                @click="toggleEdit" v-show="!edit"
                            >
                                <font-awesome-icon icon="edit"/> Edit
                            </CButton>
                        </template>
                        <template v-if="form.status == 'Inactive'">
                            <CButton 
                                v-if="$store.getters['can']('restore-form')"
                                size="sm"
                                color="success" 
                                class="float-right" 
                                @click="restoreForm()"
                            >
                            <font-awesome-icon icon="trash-restore"/> Restore
                            </CButton>
                        </template>
                    </CCardHeader>
                     <CCardBody :key="view_key" v-if="form_loaded">
                        <!-- <template v-if="total_fields_with_value > 0">
                            <CRow>
                                <CCol lg="3">
                                    <h5>NOTE:</h5>
                                </CCol>
                                <CCol lg="12">
                                    <h5 style="color: red;"><font-awesome-icon icon="exclamation-circle"/> This form cannot be edit or update. For it is being used in form data.</h5>
                                </CCol>
                            </CRow>
                            <br>
                        </template> -->
                        <CTabs  variant="tabs" :active-tab="current_tab" fade @update:activeTab="changeTab" >
                            <CTab title="Description">
                                <br>
                                <CRow>
                                    <CCol lg="12">
                                        <label>Status</label>: <CBadge :color="getColorStatus(form.status)">{{form.status}}</CBadge>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg="12">
                                        <CInput 
                                            :lazy="false"
                                            :value.sync="$v.form.name.$model"
                                            :isValid="checkIfValid($v.form, 'name')"
                                            label="Name" 
                                            type="text" 
                                            placeholder="CRF Form" 
                                            autocomplete="off"  
                                            v-model="form.name"
                                            :disabled="!edit"

                                            invalidFeedback="Must be more than (3) characters."
                                        />
                                    </CCol>
                                   
                                </CRow>
                                <CRow>
                                    <CCol lg="6">
                                        <label>Prefix Reference Number</label>
                                            <v-select 
                                                taggable
                                                :lazy="false"
                                                :value.sync="$v.form.prefix.$model"
                                                :isValid="checkIfValid($v.form, 'prefix')"
                                                :disabled="!edit"
                                                label="Prefix Reference Number"
                                                :options="prefix_list"
                                                v-model="form.prefix"
                                                :clearable="false"
                                                :class="!form.prefix ? 'has-error' : 'has-success'"
                                            />
                                    </CCol>
                                    <CCol lg="6">
                                            <label>PrintOut Source</label>
                                            <v-select 
                                                :lazy="false"
                                                :value.sync="$v.form.printout_source.$model"
                                                :isValid="checkIfValid($v.form, 'printout_source')"
                                                :disabled="!edit"
                                                label="Printout Source"
                                                :options="printout_list"
                                                v-model="form.printout_source"
                                                :clearable="false"
                                                :class="!form.printout_source ? 'has-error' : 'has-success'"
                                            />
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol lg="12">
                                        <CTextarea
                                            
                                            :disabled="!edit"
                                            :lazy="false"
                                            :value.sync="$v.form.description.$model"
                                            :isValid="checkIfValid($v.form, 'description')"

                                            class="grp-text-area"
                                            label="Description"
                                            placeholder="Content..."
                                            autocomplete="off"  
                                            rows="4"
                                            
                                            v-model="form.description"
                                        />
                                    </CCol>
                                </CRow>
                                <!-- <br>
                                <CRow>
                                    <CCol lg="6">
                                        <CRow>
                                            <CCol lg="3">
                                                External Use <a-popover title="NOTE:" placement="left">
                                                                <template slot="content">
                                                                    <span>When the form is for external use, this will only be accessible to external users/submitters.</span>
                                                                </template>
                                                                <font-awesome-icon 
                                                                    style="color: #4caf50;" 
                                                                    icon="info-circle" 
                                                                    size="lg"
                                                                />
                                                            </a-popover>
                                            </CCol>
                                            <CCol lg="9">
                                                <p-check
                                                    :disabled="!edit"
                                                    class="p-icon p-bigger p-round" 
                                                    color="success" off-color="danger" toggle
                                                    v-model="form.external_use"
                                                >
                                                    <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                                    Yes
                                                    <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                                    <label slot="off-label">No</label>
                                                </p-check>  
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CRow> -->
                                <hr>
                                <CRow>
                                    <CCol lg="12">
                                        <CCardBody class="crd-bdy-brdr">
                                            <CRow>
                                                <CCol lg="12">
                                                    <h6>Header(s)</h6>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol lg="6">
                                                   <CRow>
                                                       <CCol lg="4">
                                                           Column
                                                       </CCol>
                                                       <CCol lg="8">
                                                           <CInput 
                                                                
                                                                :disabled="!edit"
                                                                :lazy="false"
                                                                :value.sync="$v.form.header_column.$model"
                                                                :isValid="checkIfValid($v.form, 'header_column')"

                                                                placeholder="(e.g '1')"
                                                                autocomplete="off"  
                                                                
                                                                v-model="form.header_column"
                                                                
                                                            />
                                                                <!-- invalidFeedback="Numeric input only from 1-9. Max of one." -->
                                                       </CCol>
                                                   </CRow>
                                                </CCol>
                                                <CCol lg="6">
                                                   <CRow>
                                                       <CCol lg="4">
                                                           Row
                                                       </CCol>
                                                       <CCol lg="8">
                                                           <CInput 
                                                                
                                                                :disabled="!edit"
                                                                :lazy="false"
                                                                :value.sync="$v.form.header_row.$model"
                                                                :isValid="checkIfValid($v.form, 'header_row')"

                                                                placeholder="(e.g '1')" 
                                                                autocomplete="off"  
                                                                v-model="form.header_row"
                                                            />
                                                       </CCol>
                                                                <!-- :invalidFeedback="form.printout_source == 'Default' ? 'Numeric input only from 1-9. Max of one.' : 'Numeric input only from 1-30. Max of two.'" -->
                                                   </CRow>
                                                </CCol>
                                                <CCol lg="6">
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol lg="12">
                                        <CCardBody class="crd-bdy-brdr">
                                            <CRow>
                                                <CCol lg="12">
                                                    <h6>Detail(s)</h6>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol lg="6">
                                                   <CRow>
                                                       <CCol lg="4">
                                                           Column
                                                       </CCol>
                                                       <CCol lg="8">
                                                           <CInput 
                                                                
                                                                :disabled="!edit || !form.has_details"
                                                                :lazy="false"
                                                                :value.sync="$v.form.detail_column.$model"
                                                                :isValid="checkIfValid($v.form, 'detail_column')"

                                                                placeholder="(e.g '1')"
                                                                autocomplete="off"  
                                                                v-model="form.detail_column"
                                                            />
                                                                <!-- invalidFeedback="Numeric input only from 1-9. Max of one." -->
                                                       </CCol>
                                                   </CRow>
                                                </CCol>
                                                <CCol lg="6">
                                                    <CRow>
                                                        <CCol lg="4">
                                                            
                                                            <template v-if="total_fields_with_value > 0">
                                                                <a-popover title="NOTE!" placement="top">
                                                                    <template slot="content">
                                                                        <small>This cannot be changed because this form has already been used.</small>
                                                                    </template>
                                                                    <font-awesome-icon style="color: #3d98b9; " icon="info-circle" size="lg"/>
                                                                </a-popover> 
                                                            </template>
                                                            <label>&nbsp;Required details </label>
                                                        </CCol>
                                                        <CCol lg="8">
                                                            <template v-if="total_fields_with_value <= 0">
                                                                <p-check 
                                                                    :disabled="!edit"
                                                                    class="p-icon p-bigger p-round" 
                                                                    color="success" off-color="danger" toggle
                                                                    v-model="form.has_details"
                                                                >
                                                                    <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                                                    Required
                                                                    <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                                                    <label slot="off-label">Not Required</label>
                                                                </p-check>  
                                                                
                                                            </template>
                                                            <template v-else>
                                                                <p-check 
                                                                    disabled
                                                                    class="p-icon p-bigger p-round" 
                                                                    color="success" off-color="danger" toggle
                                                                    checked
                                                                >
                                                                    <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                                                    Required
                                                                    <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                                                    <label slot="off-label">Not Required</label>
                                                                </p-check> 
                                                            </template>
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol lg="12">
                                        <CCardBody class="crd-bdy-brdr">
                                            <CRow>
                                                <CCol lg="12">
                                                    <h6>Footer(s)</h6>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol lg="6">
                                                   <CRow>
                                                       <CCol lg="4">
                                                           Column
                                                       </CCol>
                                                       <CCol lg="8">
                                                           <CInput 
                                                                
                                                                :disabled="!edit"
                                                                :lazy="false"
                                                                :value.sync="$v.form.footer_column.$model"
                                                                :isValid="checkIfValid($v.form, 'footer_column')"

                                                                placeholder="(e.g '1')"
                                                                autocomplete="off"  
                                                                v-model="form.footer_column"
                                                            />
                                                                <!-- invalidFeedback="Numeric input only from 1-9. Max of one." -->
                                                       </CCol>
                                                   </CRow>
                                                </CCol>
                                                <CCol lg="6">
                                                   <CRow>
                                                       <CCol lg="4">
                                                           Row
                                                       </CCol>
                                                       <CCol lg="8">
                                                           <CInput 
                                                                
                                                                :disabled="!edit"
                                                                :lazy="false"
                                                                :value.sync="$v.form.footer_row.$model"
                                                                :isValid="checkIfValid($v.form, 'footer_row')"

                                                                placeholder="(e.g '1')" 
                                                                autocomplete="off"  
                                                                v-model="form.footer_row"
                                                            />
                                                                <!-- invalidFeedback="Numeric input only from 1-9. Max of one." -->
                                                       </CCol>
                                                   </CRow>
                                                </CCol>
                                                <CCol lg="6">
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol lg="12">
                                        <CButton 
                                            color="primary"
                                            class="float-right"
                                            :title="!isFormValid ? 'Please fill out all fields.' : 'Proceed'"
                                            :disabled="!isFormValid"
                                            @click="isFormValid ? changeTab(1) : ''"
                                        >
                                        <font-awesome-icon icon="angle-right" /> Next
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab title="Headers & Footers" :disabled="!isFormValid">
                                <template v-if="edit">
                                    <br>
                                    <CRow>
                                        <CCol lg="3">
                                            Select Field
                                        </CCol>
                                        <CCol lg="8">
                                            <v-select 
                                                placeholder="Select one."
                                                label="display_name"
                                                :options="custom_fields"
                                                v-model="drpdwn_field_header_footer"
                                                :class="!drpdwn_field_header_footer ? 'has-error' : 'has-success'"
                                            >
                                                <template #option="{ display_name, field_type, parameter_name }">
                                                    <h6 style="margin: 0">{{ display_name }}</h6>
                                                    Type: <em>{{ field_type.name }}</em> <br> Parameter: <em>{{ parameter_name }}</em>
                                                </template>
                                            </v-select>
                                        </CCol>
                                        <CCol lg="1">
                                             <CButton 
                                                class="float-left"
                                                color="primary" 
                                                title="Add new"
                                                @click="modal_new_field = true"
                                            >
                                                <font-awesome-icon icon="plus"/>
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                    <br>
                                    <!-- <template v-if="drpdwn_field_header_footer && !header_footer_field.some(function(item) { return item.is_reference})">
                                        <CRow>
                                            <CCol lg="3">
                                                Set as Reference
                                            </CCol>
                                            <CCol lg="9">
                                                <p-check 
                                                    :readonly="!header_footer.usage"
                                                    :disabled="header_footer_field.some(function(item) { return item.is_reference})"
                                                    class="p-icon p-bigger" 
                                                    color="success" 
                                                    value="true"
                                                    v-model="header_footer.is_reference"
                                                >
                                                    <font-awesome-icon class="icon" icon="check"/>
                                                </p-check> 
                                            </CCol>
                                        </CRow>
                                        <br>
                                    </template> -->
                                     <!--  -->
                                    <template v-if="drpdwn_field_header_footer.parameter_name != 'empty_block' && (drpdwn_field_header_footer.field_type ? drpdwn_field_header_footer.field_type.name != 'label' : '' )">
                                        <CRow>
                                            <CCol lg="3">
                                                Readonly
                                            </CCol>
                                            <CCol lg="9">
                                                <p-check 
                                                    class="p-icon p-bigger" 
                                                    color="success" 
                                                    v-model="header_footer.field_read_only">
                                                        <font-awesome-icon class="icon" icon="check"/>
                                                </p-check>  
                                            </CCol>
                                        </CRow>
                                        <br>
                                        <CRow>
                                            <CCol lg="3">
                                                Custom Name &nbsp; &nbsp; 
                                                <a-popover title="NOTE:" placement="topRight">
                                                    <template slot="content" >
                                                    <p>This will override the field's default display name; if not filled out, <br>
                                                        the field's provided display name will be used.
                                                    </p>
                                                    </template>
                                                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                                                </a-popover>
                                            </CCol>
                                            <CCol lg="9">
                                                <!-- v-nospecialcharacter -->
                                                <CInput 
                                                    type="text" 
                                                    placeholder="Enter custom name." 
                                                    autocomplete="off"  
                                                    v-model="header_footer.field_custom_name"
                                                />
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol lg="3">
                                                Validations
                                            </CCol>
                                            <CCol lg="9">
                                                <CInput
                                                    type="text" 
                                                    placeholder="(e.g 'required', 'max:20')" 
                                                    autocomplete="off"  
                                                    v-model="header_footer.field_validation"
                                                />
                                            </CCol>
                                        </CRow>
                                    </template>
                                    <template v-if="header_footer.field_source_type != 'none' && header_footer.field_source_type != null ">
                                        <CRow >
                                            <CCol lg="3">
                                                Source
                                            </CCol>
                                            <CCol lg="9">
                                                <CInput 
                                                    v-if="header_footer.field_source_type == 'api'"
                                                    disabled
                                                    type="text" 
                                                    autocomplete="off"  
                                                    v-model="header_footer.field_source"
                                                />
                                                
                                                <a-popover placement="right" 
                                                    v-if="header_footer.field_source_type != 'api'"
                                                    style="
                                                        padding: 10px;
                                                        border: 1px solid grey;
                                                        border-radius: 12px;
                                                        background-color: lightgrey;
                                                        color: black
                                                    ">
                                                    <template slot="title">
                                                        <span>{{header_footer.field_display_name}} values</span>
                                                    </template>
                                                    <template slot="content">
                                                        <span v-for="(element, index) in header_footer.drop_down_value" v-bind:key="index">
                                                            <template v-if="index <= 29"> {{index+1}}.) {{element}} <br></template>
                                                            <template v-if="index == 30"> ..... </template>
                                                        </span>
                                                    </template>
                                                        <font-awesome-icon icon="eye" /> Show Options
                                                </a-popover>
                                                




                                                
                                                <!-- <CButton
                                                    v-if="header_footer.field_source_type != 'api'"
                                                    color="warning"
                                                    title="Please add atleast one field."
                                                >
                                                <font-awesome-icon icon="eye" /> Show Options
                                                </CButton> -->
                                            </CCol>
                                        </CRow>
                                        <br v-if="header_footer.field_source_type != 'api'">
                                    </template>
                                    
                                    <CRow>
                                        <CCol lg="3">
                                            Usage
                                        </CCol>
                                        <CCol lg="9">
                                            <v-select 
                                                placeholder="Select one."
                                                label="name"
                                                :options="usages"
                                                v-model="drpdwn_usage_header_footer"
                                                :class="!drpdwn_usage_header_footer ? 'has-error' : 'has-success'"
                                            />
                                        </CCol>
                                    </CRow>
                                    <br>
                                    <CRow>
                                        <CCol lg="3">
                                            Column
                                        </CCol>
                                        <CCol lg="9">
                                            <CInput 
                                                :disabled="!header_footer.usage"
                                                :lazy="false"
                                                :value.sync="$v.header_footer.column.$model"
                                                :isValid="checkIfValid($v.header_footer, 'column')"

                                                type="text" 
                                                placeholder="(e.g '1', '2', '3')" 
                                                autocomplete="off"  
                                                v-model="header_footer.column"
                                                :invalidFeedback="invalidFeedbackForColumn($v, 'header_footer')"
                                                
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="3">
                                            Row
                                        </CCol>
                                        <CCol lg="9">
                                            <CInput 
                                                :disabled="!header_footer.usage"
                                                :lazy="false"
                                                :value.sync="$v.header_footer.row.$model"
                                                :isValid="checkIfValid($v.header_footer, 'row')"

                                                type="text" 
                                                placeholder="(e.g '1', '2', '3')" 
                                                autocomplete="off"  
                                                v-model="header_footer.row"
                                                :invalidFeedback="invalidFeedbackForRow($v, 'header_footer')"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="12">
                                            <CButton 
                                                :title="!isHeaderFooterValid || !header_footer.field ? 'Please fill out all fields.' : 'Proceed'"
                                                :disabled="!isHeaderFooterValid || !header_footer.field"
                                                class="float-right"
                                                color="primary" 
                                                
                                                @click="isHeaderFooterValid || !header_footer.field ? addFieldToHeaderFooter() : ''"
                                                >
                                                <font-awesome-icon icon="plus"/> Add Field
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </template>
                                <br>
                                <template>
                                    <CDataTable
                                        :items="header_footer_field"
                                        :fields="header_footer_field_fields"
                                        :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                        :items-per-page="10"
                                        border
                                        items-per-page-select
                                        sorter
                                        pagination
                                    >
                                    <!-- <template #is_reference="{item}">
                                        <td v-if="item.display_name == 'Empty Block'">
                                            n/a                                            
                                        </td>
                                        <td v-else>
                                            <p-radio 
                                                class="p-icon p-curve p-bigger" 
                                                name="is_reference" 
                                                color="success" 
                                                :value="item.random_id"
                                                @change="changeReference()"
                                                v-model="selected_reference"
                                            >
                                                <font-awesome-icon class="icon" icon="check"/>
                                            </p-radio>
                                        </td>
                                    </template> -->
                                    
                                    <template #readonly="{item}">
                                        <td v-if="item.display_name == 'Empty Block' || item.type == 'label'">
                                            n/a                                            
                                        </td>
                                        <td v-else>
                                            <!-- :disabled="item.is_reference" -->
                                            <!-- <CBadge :color="getColorStatus(item.is_required ? 'Yes' : 'No')">
                                                {{item.is_required ? 'Yes' : 'No'}}
                                            </CBadge> -->
                                            <p-check 
                                                :disabled="!edit"
                                                class="p-icon p-bigger" 
                                                color="success" 
                                                value="true"
                                                v-model="item.readonly"
                                            >
                                                <font-awesome-icon class="icon" icon="check"/>
                                            </p-check>
                                        </td>
                                    </template>
                                    <template #type="{item}">
                                        <td>
                                            <CInput
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.type"
                                                v-model="item.type"
                                                disabled
                                            />
                                        </td>
                                    </template>
                                    <template #display_name="{item, index}">
                                        <td>
                                            <v-select 
                                                :disabled="!edit || !parseInt(item.hf_field_value_count) <= 0"
                                                @option:selected="selectChangeDisplayName(header_footer_field, 'header_footer', item, index)"
                                                label="display_name"
                                                :options="custom_fields"
                                                v-model="item.display_name"
                                                :class="!item.display_name ? 'has-error' : 'has-success'"
                                            />
                                           
                                        </td>
                                    </template>
                                    <!-- <template #source={item}>
                                        <td>
                                            <CInput
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.source"
                                                v-model="item.source"
                                                :class="item.source_type == 'api' ?
                                                           (!item.source ? 'has-inp-error' : 'has-inp-success') : 'has-inp-success'"
                                            />
                                        </td>
                                    </template> -->
                                    <template #usage="{item, index}">
                                        <td>
                                            <v-select 
                                                :disabled="!edit || !parseInt(item.hf_field_value_count) <= 0"
                                                @option:selected="selectChangeUsage(item, index)"
                                                label="name"
                                                :options="usages"
                                                v-model="item.usage"
                                                :class="!item.usage ? 'has-error' : 'has-success'"
                                            />
                                        </td>
                                    </template>
                                    <template #field_custom_name="{item}">
                                        <td v-if="item.display_name == 'Empty Block' || item.type == 'label'">
                                            n/a                                            
                                        </td>
                                        <!-- v-nospecialcharacter -->
                                        <td v-else>
                                            <CInput
                                                :disabled="!edit"
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.field_custom_name"
                                                v-model="item.field_custom_name"
                                            />
                                        </td>
                                    </template>
                                    <template #validation="{item}">
                                        <td v-if="item.display_name == 'Empty Block' || item.type == 'label'">
                                            n/a                                            
                                        </td>
                                        <td v-else>
                                            <CInput
                                                :disabled="!edit"
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.validation"
                                                v-model="item.validation"
                                            />
                                        </td>
                                    </template>
                                    <template #column="{item}">
                                        <td>
                                            <CInput
                                                :disabled="!edit"
                                                v-numberonly
                                                style="width:50px"
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.column"
                                                v-model="item.column"
                                                :class="!item.column ? 'has-inp-error' : 'has-inp-success'"
                                            />
                                        </td>
                                    </template>
                                    <template #row="{item}">
                                        <td>
                                            <CInput
                                                :disabled="!edit"
                                                v-numberonly
                                                maxlength="2"
                                                style="width:50px"
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.row"
                                                v-model="item.row"
                                                :class="!item.row ? 'has-inp-error' : 'has-inp-success'"
                                            />
                                        </td>
                                    </template>
                                    <template #action="{index, item}">
                                        <td v-if="parseInt(item.hf_field_value_count) <= 0">
                                            <CButton 
                                                color="danger" 
                                                shape="pill" 
                                                size="sm"
                                                @click="removeFieldToTable(index, item, 'header_footer_field')"
                                            >
                                                <font-awesome-icon icon="times"/>
                                            </CButton>
                                        </td>
                                        <td v-else>
                                            <i>Already in used</i>
                                        </td>
                                    </template>
                                    </CDataTable>
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                        <CButton 
                                            color="primary"
                                            class="float-right"
                                            @click="changeTab(2)"
                                            title="Please add atleast one field."
                                            :disabled="header_footer_field.length <= 0"
                                        >
                                        <font-awesome-icon icon="angle-right" /> Next
                                        </CButton>
                                         <CButton 
                                            color="secondary"
                                            class="float-right"
                                            @click="changeTab(0)"
                                        >
                                        <font-awesome-icon icon="angle-left" /> Back
                                        </CButton>
                                    </CCol>
                                </CRow> 
                            </CTab>
                            <CTab title="Details" :disabled="header_footer_field.length <= 0">


                                <template v-if="!form.has_details">
                                    <br>
                                    <CRow>
                                        <CCol lg="12">
                                            <CAlert show color="warning">
                                                <h5>Details is not required.</h5>
                                                <hr>
                                                <p class="mb-0">
                                                    No need to add fields here.
                                                </p>
                                            </CAlert>
                                        </CCol>
                                    </CRow>
                                </template>

                                <template v-if="edit">
                                    
                                    
                                    <template v-if="form.has_details">
                                        <br>
                                        <CRow>
                                            <CCol lg="3">
                                                Select Field
                                            </CCol>
                                            <CCol lg="8">
                                                <v-select 
                                                    placeholder="Select one."
                                                    label="display_name"
                                                    :options="custom_fields"
                                                    v-model="drpdwn_field_detail"
                                                    :class="!drpdwn_field_detail ? 'has-error' : 'has-success'"
                                                >
                                                    <template #option="{ display_name, field_type, parameter_name }">
                                                        <h6 style="margin: 0">{{ display_name }}</h6>
                                                        Type: <em>{{ field_type.name }}</em> <br> Parameter: <em>{{ parameter_name }}</em>
                                                    </template>
                                                </v-select>
                                            </CCol>
                                            <CCol lg="1">
                                                <CButton 
                                                    class="float-left"
                                                    color="primary" 
                                                    title="Add new"
                                                    @click="modal_new_field = true"
                                                >
                                                    <font-awesome-icon icon="plus"/>
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                        <br>
                                        <template v-if="drpdwn_field_detail.parameter_name != 'empty_block'  && (drpdwn_field_detail.field_type ? drpdwn_field_detail.field_type.name != 'label' : '' ) ">
                                            <CRow>
                                                <CCol lg="3">
                                                    Readonly
                                                </CCol>
                                                <CCol lg="9">
                                                    <p-check 
                                                        class="p-icon p-bigger" 
                                                        color="success" 
                                                        v-model="detail.field_read_only">
                                                            <font-awesome-icon class="icon" icon="check"/>
                                                    </p-check>  
                                                </CCol>
                                            </CRow>
                                            <br>
                                            <CRow>
                                                <CCol lg="3">
                                                    Custom Name &nbsp; &nbsp; 
                                                    <a-popover title="NOTE:" placement="topRight">
                                                        <template slot="content" >
                                                        <p>This will override the field's default display name; if not filled out, <br>
                                                            the field's provided display name will be used.
                                                        </p>
                                                        </template>
                                                            <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                                                    </a-popover>
                                                </CCol>
                                                <!-- v-nospecialcharacter -->
                                                <CCol lg="9">
                                                    <CInput 
                                                        type="text" 
                                                        placeholder="Enter custom name." 
                                                        autocomplete="off"  
                                                        v-model="detail.field_custom_name"
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol lg="3">
                                                    Validations
                                                </CCol>
                                                <CCol lg="9">
                                                    <CInput 
                                                        type="text" 
                                                        placeholder="(e.g 'required', 'max:20')" 
                                                        autocomplete="off"  
                                                        v-model="detail.field_validation"
                                                    />
                                                </CCol>
                                            </CRow>
                                        </template>
                                        <template v-if="detail.field_source_type != 'none' && detail.field_source_type != null ">
                                            <CRow >
                                                <CCol lg="3">
                                                    Source
                                                </CCol>
                                                <CCol lg="9">
                                                    <CInput 
                                                        v-if="detail.field_source_type == 'api'"
                                                        disabled
                                                        type="text" 
                                                        autocomplete="off"  
                                                        v-model="detail.field_source"
                                                    />
                                                    
                                                    <a-popover placement="right" 
                                                        v-if="detail.field_source_type != 'api'"
                                                        style="
                                                            padding: 10px;
                                                            border: 1px solid grey;
                                                            border-radius: 12px;
                                                            background-color: lightgrey;
                                                            color: black
                                                        ">
                                                        <template slot="title">
                                                            <span>{{detail.field_display_name}} values</span>
                                                        </template>
                                                        <template slot="content">
                                                            <span v-for="(element, index) in detail.drop_down_value" v-bind:key="index">
                                                            <template v-if="index <= 29"> {{index+1}}.) {{element}} <br></template>
                                                            <template v-if="index == 30"> ..... </template>
                                                            </span>
                                                        </template>
                                                            <font-awesome-icon icon="eye" /> Show Options
                                                    </a-popover>




                                                    <!-- <CButton
                                                        v-if="detail.field_source_type != 'api'"
                                                        color="warning"
                                                        title="Please add atleast one field."
                                                    >
                                                    <font-awesome-icon icon="eye" /> Show Options
                                                    </CButton> -->
                                                </CCol>
                                            </CRow>
                                            <br v-if="detail.field_source_type != 'api'">
                                        </template>
                                        <CRow>
                                            <CCol lg="3">
                                                Column
                                            </CCol>
                                            <CCol lg="9">
                                                <CInput 
                                                
                                                    :lazy="false"
                                                    :value.sync="$v.detail.column.$model"
                                                    :isValid="checkIfValid($v.detail, 'column')"

                                                    type="text" 
                                                    placeholder="(e.g '1', '2', '3')" 
                                                    autocomplete="off"  
                                                    v-model="detail.column"
                                                    :invalidFeedback="!$v.detail.column.numeric ? 'Only accept numbers' :
                                                                        !$v.detail.column.maxValue ? 'Cannot be greater than Form\'s detail column' : ''"
                                                />
                                            </CCol>
                                        </CRow>
                                        <CRow>
                                            <CCol lg="12">
                                                <CButton 
                                                    :title="!isDetailValid || !detail.field ? 'Please fill out all fields.' : 'Proceed'"
                                                    :disabled="!isDetailValid || !detail.field"
                                                    class="float-right"
                                                    color="primary" 
                                                    
                                                    @click="isDetailValid || !detail.field ? addFieldToDetail() : ''"
                                                    >
                                                    <font-awesome-icon icon="plus"/> Add Field
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                    </template>


                                </template>
                                <br>
                                <template v-if="form.has_details">
                                    <CDataTable
                                        :items="detail_field"
                                        :fields="detail_field_fields"
                                        :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                        :items-per-page="10"
                                        border
                                        items-per-page-select
                                        sorter
                                        pagination
                                    >
                                    <template #readonly="{item}">
                                        <td v-if="item.display_name == 'Empty Block' || item.type == 'label'">
                                            n/a                                            
                                        </td>
                                        <td v-else>
                                            <!-- :disabled="item.is_reference" -->
                                            <!-- <CBadge :color="getColorStatus(item.is_required ? 'Yes' : 'No')">
                                                {{item.is_required ? 'Yes' : 'No'}}
                                            </CBadge> -->
                                            <p-check 
                                                :disabled="!edit"
                                                class="p-icon p-bigger" 
                                                color="success" 
                                                value="true"
                                                v-model="item.readonly"
                                            >
                                                <font-awesome-icon class="icon" icon="check"/>
                                            </p-check>
                                        </td>
                                    </template>
                                    <template #type="{item}">
                                        <td>
                                            <CInput
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.type"
                                                v-model="item.type"
                                                disabled
                                            />
                                        </td>
                                    </template>
                                    <template #display_name="{item, index}">
                                        <td>
                                            <v-select 
                                                :disabled="!edit || !parseInt(item.d_field_value_count) <= 0"
                                                @option:selected="selectChangeDisplayName(detail_field, 'detail',  item, index)"
                                                label="display_name"
                                                :options="custom_fields"
                                                v-model="item.display_name"
                                                :class="!item.display_name ? 'has-error' : 'has-success'"
                                            />
                                        
                                        </td>
                                    </template>
                                    <!-- <template #source={item}>
                                        <td>
                                            <CInput
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.source"
                                                v-model="item.source"
                                                :class="item.source_type == 'api' ?
                                                        (!item.source ? 'has-inp-error' : 'has-inp-success') : 'has-inp-success'"
                                            />
                                        </td>
                                    </template> -->
                                    <template #field_custom_name="{item}">
                                        <td v-if="item.display_name == 'Empty Block' || item.type == 'label'">
                                            n/a                                            
                                        </td>
                                        <td v-else>
                                            <!-- :disabled="item.is_reference" -->
                                                <!-- v-nospecialcharacter -->
                                            <CInput
                                                :disabled="!edit"
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.field_custom_name"
                                                v-model="item.field_custom_name"
                                            />
                                        </td>
                                    </template>
                                    <template #validation="{item}">
                                        <td v-if="item.display_name == 'Empty Block' || item.type == 'label'">
                                            n/a                                            
                                        </td>
                                        <td v-else>
                                            <CInput
                                                :disabled="!edit"
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.validation"
                                                v-model="item.validation"
                                            />
                                        </td>
                                    </template>
                                    <template #column="{item}">
                                        <td>
                                            <CInput
                                                :disabled="!edit"
                                                style="width:50px"
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.column"
                                                v-model="item.column"
                                                :class="!item.column ? 'has-inp-error' : 'has-inp-success'"
                                            />
                                        </td>
                                    </template>
                                    <template #action="{index, item}">
                                        <td v-if="parseInt(item.d_field_value_count) <= 0">
                                            <CButton 
                                                color="danger" 
                                                shape="pill" 
                                                size="sm"
                                                @click="removeFieldToTable(index, item, 'detail_field')"
                                            >
                                                <font-awesome-icon icon="times"/>
                                            </CButton>
                                        </td>
                                        <td v-else>
                                            <i>Already in used</i>
                                        </td>
                                    </template>
                                    </CDataTable>
                                </template>
                                <CRow>
                                            <!-- :disabled="detail_field.length <= 0" -->
                                    <CCol lg="12">
                                        
                                        <CButton 
                                            color="primary"
                                            class="float-right"
                                            @click="changeTab(3)"
                                            title="Please add atleast one field."
                                            :disabled="detail_field.length <= 0 && form.has_details"
                                        >
                                        <font-awesome-icon icon="angle-right" /> Next
                                        </CButton>
                                        <CButton 
                                            color="secondary"
                                            class="float-right"
                                            @click="changeTab(1)"
                                        >
                                        <font-awesome-icon icon="angle-left" /> Back
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab title="Aggregate Functions (Optional)">
                                <template  v-if="edit">
                                    <br>
                                    <CRow>
                                        <CCol lg="3">
                                            Select Field
                                        </CCol>
                                        <CCol lg="9">
                                            <v-select 
                                                placeholder="Select one."
                                                label="display_name"
                                                :options="custom_fields"
                                                v-model="drpdwn_field_aggregate"
                                                :class="!drpdwn_field_aggregate ? 'has-error' : 'has-success'"
                                            />
                                        </CCol>
                                    </CRow>
                                    <br>
                                    <template v-if="drpdwn_field_aggregate.parameter_name == 'aggregate_function'">
                                        <CRow>
                                            <CCol lg="3">
                                                Aggregate Function
                                            </CCol>
                                            <CCol lg="9">
                                                <v-select 
                                                    placeholder="Select one."
                                                    label="value"
                                                    :options="fields.length > 0 ? fields.find(obj => { return obj.parameter_name === 'aggregate_function' }).field_drop_down_values_data : []"
                                                    v-model="aggregate.function"
                                                    :class="!aggregate.function ? 'has-error' : 'has-success'"
                                                />
                                            </CCol>
                                        </CRow>
                                        <br>
                                        <template v-if="detail_field.length > 0">
                                            <CRow>
                                                <CCol lg="3">
                                                    Details Fields to Aggregate
                                                </CCol>
                                                <CCol lg="9">
                                                    <v-select 
                                                        placeholder="Select one."
                                                        label="display_name"
                                                        :options="detail_field"
                                                        v-model="aggregate.aggregate_field_id"
                                                        :class="!aggregate.aggregate_field_id ? 'has-error' : 'has-success'"
                                                    />
                                                </CCol>
                                            </CRow>
                                            <br>
                                        </template>
                                    </template>
                                    <CRow>
                                        <CCol lg="3">
                                            Usage
                                        </CCol>
                                        <CCol lg="9">
                                            <v-select 
                                                placeholder="Select one."
                                                label="name"
                                                :options="usages"
                                                v-model="drpdwn_usage_aggregate"
                                                :class="!drpdwn_usage_aggregate ? 'has-error' : 'has-success'"
                                            />
                                        </CCol>
                                    </CRow>
                                    <br>
                                    <CRow>
                                        <CCol lg="3">
                                            Column
                                        </CCol>
                                        <CCol lg="9">
                                            <CInput 
                                                :disabled="!aggregate.usage"
                                                :lazy="false"
                                                :value.sync="$v.aggregate.column.$model"
                                                :isValid="checkIfValid($v.aggregate, 'column')"

                                                type="text" 
                                                placeholder="(e.g '1', '2', '3')" 
                                                autocomplete="off"  
                                                v-model="aggregate.column"
                                                :invalidFeedback="invalidFeedbackForColumn($v, 'aggregate')"
                                                
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="3">
                                            Row
                                        </CCol>
                                        <CCol lg="9">
                                            <CInput 
                                                :disabled="!aggregate.usage"
                                                :lazy="false"
                                                :value.sync="$v.aggregate.row.$model"
                                                :isValid="checkIfValid($v.aggregate, 'row')"

                                                type="text" 
                                                placeholder="(e.g '1', '2', '3')" 
                                                autocomplete="off"  
                                                v-model="aggregate.row"
                                                :invalidFeedback="invalidFeedbackForRow($v, 'aggregate')"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="12">
                                            <CButton 
                                                :title="!isAggregateValid || !aggregate.field ? 'Please fill out all fields.' : 'Proceed'"
                                                :disabled="!isAggregateValid || !aggregate.field"
                                                class="float-right"
                                                color="primary" 
                                                
                                                @click="isAggregateValid || !header_footer.field ? addAggregateToHeaderFooter() : ''"
                                                >
                                                <font-awesome-icon icon="plus"/> Add Field
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </template>
                                <br>
                                <template>
                                     <CDataTable
                                        :items="aggregate_field"
                                        :fields="header_footer_field_fields"
                                        :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                        :items-per-page="10"
                                        border
                                        items-per-page-select
                                        sorter
                                        pagination
                                    >
                                        <template #column="{item}">
                                            <td>
                                                <CInput
                                                    :disabled="!edit"
                                                    v-numberonly
                                                    style="width:50px"
                                                    type="text" 
                                                    autocomplete="off"  
                                                    :value="item.column"
                                                    v-model="item.column"
                                                    :class="!item.column ? 'has-inp-error' : 'has-inp-success'"
                                                />
                                            </td>
                                        </template>
                                        <template #row="{item}">
                                            <td>
                                                <CInput
                                                    :disabled="!edit"
                                                    v-numberonly
                                                    maxlength="2"
                                                    style="width:50px"
                                                    type="text" 
                                                    autocomplete="off"  
                                                    :value="item.row"
                                                    v-model="item.row"
                                                    :class="!item.row ? 'has-inp-error' : 'has-inp-success'"
                                                />
                                            </td>
                                        </template>
                                        <template #field_custom_name>
                                            <td>
                                                n/a
                                            </td>
                                        </template>
                                        <template #usage="{item, index}">
                                            <td>
                                                <v-select 
                                                    :disabled="!edit"
                                                    @option:selected="selectChangeUsage(item, index)"
                                                    label="name"
                                                    :options="usages"
                                                    v-model="item.usage"
                                                    :class="!item.usage ? 'has-error' : 'has-success'"
                                                />
                                            </td>
                                        </template>
                                        <template #aggregate_function="{item}">
                                            <td v-if="item.display_name == 'Empty Block'">
                                                n/a                                            
                                            </td>
                                            <td v-else>
                                                <!-- :disabled="item.is_reference" -->
                                                <!-- <CBadge :color="getColorStatus(item.is_required ? 'Yes' : 'No')">
                                                    {{item.is_required ? 'Yes' : 'No'}}
                                                </CBadge> -->
                                                <v-select 
                                                    :disabled="!edit"
                                                    label="value"
                                                    :options="fields.length > 0 ? fields.find(obj => { return obj.parameter_name === 'aggregate_function' }).field_drop_down_values_data : []"
                                                    v-model="item.aggregate_function"
                                                    :class="!item.aggregate_function ? 'has-error' : 'has-success'"
                                                />
                                            </td>
                                        </template>
                                        <template #aggregate_column_field_id="{item}">
                                            <td v-if="item.display_name == 'Empty Block'">
                                                n/a                                            
                                            </td>
                                            <td v-else>
                                                <!-- :disabled="item.is_reference" -->
                                                <!-- <CBadge :color="getColorStatus(item.is_required ? 'Yes' : 'No')">
                                                    {{item.is_required ? 'Yes' : 'No'}}
                                                </CBadge> -->
                                                <v-select 
                                                    :disabled="!edit"
                                                    label="display_name"
                                                    :options="detail_field"
                                                    :reduce="display_name => display_name.field_id"
                                                    v-model="item.aggregate_column_field_id"
                                                    :class="!item.aggregate_column_field_id ? 'has-error' : 'has-success'"
                                                />
                                            </td>
                                        </template>
                                        <template #action="{index, item}">
                                            <td>
                                                <CButton 
                                                    color="danger" 
                                                    shape="pill" 
                                                    size="sm"
                                                    @click="removeFieldToTable(index, item, 'aggregate_field')"
                                                >
                                                    <font-awesome-icon icon="times"/>
                                                </CButton>
                                            </td>
                                        </template>
                                     </CDataTable>
                                </template>
                            </CTab>
                        </CTabs>
                     </CCardBody>
                </CCard>
                
                <CRow>
                    <CCol lg="12">
                        <CRow v-show="edit">
                            <CCol lg="12">
                                <CCard borderColor="danger" v-if="$store.getters['can']('archive-form')">
                                    <CCardHeader>
                                        <font-awesome-icon 
                                            icon="exclamation" 
                                            :style="{ color: 'red' }"
                                        /> 
                                        Danger Zone
                                    </CCardHeader>
                                    <CCardBody>
                                        <CRow>
                                            <CCol lg="8">
                                                <h6>Archive this Form ?</h6>
                                            </CCol>
                                            <CCol lg="12">
                                                <label> Once form is archived, it will not be use anymore but Form Data/Application still viewable. Please be certain.</label>
                                            </CCol>
                                        </CRow>
                                        <br>
                                        <CRow>
                                            <CCol lg="3">
                                                <CButton shape="pill" color="warning" @click="archive()" > 
                                                    <font-awesome-icon icon="minus-square" /> Archived 
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
        <CModal 
            color="primary" 
            :show.sync="form_review" 
            :closeOnBackdrop="false"
            centered
            size="xl"
        >
            <template #header>
                <h5><font-awesome-icon icon="folder-open" /> Form Review (Actual Layout)</h5>
                <CButton
                    class="pull-right"
                    color="light"
                    shape="pill"
                    size="sm"
                    @click="form_review = false"
                >
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper v-if="form_review">
                <FormPreview ref="formPreview"/>
            </template>
            <template #footer>
                <CRow>
                    <CButton
                        size="sm"
                        color="secondary" 
                    >
                        <font-awesome-icon icon="times" /> Cancel
                    </CButton>
                    <CButton
                        size="sm"
                        color="primary" 
                        @click="updateForm()"
                    >
                        <font-awesome-icon icon="folder-open" /> Update Form
                    </CButton>
                </CRow>
            </template>
        </CModal>
        <CModal 
            color="primary" 
            :show.sync="modal_new_field" 
            centered
            size="xl"
        >
            <template #header>
                <h5><font-awesome-icon icon="pencil-alt" /> Create New Field</h5>
                <CButton
                    class="pull-right"
                    color="light"
                    shape="pill"
                    size="sm"
                    @click="modal_new_field = false"
                >
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper>
                <CRow>
                    <CCol lg="12">
                        <CCardBody>
                            <a-popover title="NOTE:" placement="left" >
                                <template slot="content">
                                    <FieldHelp/>
                                </template>
                                <font-awesome-icon 
                                    style="color: #4caf50; float: right;" 
                                    icon="question-circle" 
                                    size="lg"
                                />
                            </a-popover>
                            <br>
                            <br>
                            <FieldCreateForm ref="fieldCreateForm"/>
                        </CCardBody>
                    </CCol>
                </CRow>
            </template>
            <template #footer>
                <hr hide>
            </template>
        </CModal>
    </div>
</template>

<style>
    .ant-popover {
        z-index: 9999;   
    }
</style>
<script>
import FormManagementJS from '../js/form-management.js';
import FormPreview from './components/FormPreview.vue';
import FieldHelp from '../components/FieldHelp.vue';
import FieldCreateForm from '../field/components/FieldCreateForm.vue'
import { required, numeric, minValue, maxValue, minLength, maxLength, requiredIf, url, helpers} from "vuelidate/lib/validators"
// const checkMaxValue = (prop) =>
//   helpers.withParams({ type: 'validatedIf', prop }, function(value, parentVm) {
//     if(helpers.ref(prop, this, parentVm) == 'Default') {
//         return value > 0 && value <= 9 && value.length == 1 ? true : false;
//     } else {
//         return value > 0 && value <= 30 && value.length <= 2 ? true : false;
//     }
//   })
export default {
    name: 'FormEdit',
    components: {
        FieldCreateForm,
        FormPreview,
        FieldHelp,
    },
    data() {
        return {
            printout_list: [],
            prefix_list: [],
            view_key: 0,
            form_review: false,

            data_backup: null,

            form_loaded: false,

            disable:1,
            edit: false,
            current_tab:0,

            
            form: {
                name: '',
                prefix: '',
                printout_source: '',
                description: '',
                // external_use: null,
                header_column: null,
                header_row: null,
                detail_column: null,
                footer_column: null,
                footer_row: null,
                status: null,
                has_details: true,
            },
            
            modal_new_field: false,

            fields: [],

            
            usages: [
                {name: 'header'}, 
                {name: 'footer'}, 
            ],
            
            form_review: false,

            
            drpdwn_field_header_footer: '',
            drpdwn_usage_header_footer: '',

            
            header_footer: {
                field_id: null,
                field_display_name: null,
                field_custom_name: null,
                field_type:null,
                field_validation: null,
                field_source: null,
                field_source_type: null,
                field_read_only: false,
                usage: null,
                column: null,
                row: null,
                drop_down_value: null,
                label_value: null,
                label_format: null,
            },
            
            header_footer_field: [
            ],

            drpdwn_field_aggregate: '',
            drpdwn_usage_aggregate: '',
            
            aggregate: {
                field_display_name: null,
                field_type:null,
                function:null,
                aggregate_field_id:null,
                usage: null,
                column: null,
                row: null,
            },

            aggregate_field: [
                // {"random_id":127,"field_id":null,"type":null,"display_name":"Empty Block","usage":"footer","column":"1","row":"1","aggregate_field":true,"aggregate_function":null,"aggregate_column":null},
                // {"random_id":122,"field_id":null,"type":null,"display_name":"Empty Block","usage":"footer","column":"2","row":"1","aggregate_field":true,"aggregate_function":null,"aggregate_column":null},
                // {"random_id":119,"field_id":null,"type":null,"display_name":"Aggregate Function","usage":"footer","column":"3","row":"1","aggregate_field":true,"aggregate_function":"sum","aggregate_column":"amount"}
            ],

            

            drpdwn_field_detail: '',

            detail: {
                field_id: null,
                field_display_name: null,
                field_custom_name: null,
                field_type:null,
                field_validation: null,
                field_source: null,
                field_source_type: null,
                column: null,
                field_read_only: false,
                parameter_name: null,
                drop_down_value: null,
                label_value: null,
                label_format: null,
            },
            
            detail_field: [],

            recently_removed_header_footer_id: [],
            recently_removed_detail_id: [],

            over_all_hf_field_value_count: 0,
            over_all_d_field_value_count: 0,

            total_fields_with_value: 0, // Total fields with value 
        }
    },
    created() {
        this.$emit('activeTab', 0);
        
        this.$Progress.start();
        this.getFields();
        this.getPrintoutSource();
        this.getPrefixList();
        this.getForm();
    },
    validations: {
        // return {

            form: {
                name: { 
                    required, 
                    minLength: minLength(3)
                },
                description: { 
                    required, 
                },
                prefix: { 
                    required,
                },
                printout_source: { 
                    required,
                },

                header_column: { 
                    required, 
                    numeric, 
                    // minValue:minValue(1), 
                    // maxValue:maxValue(9),
                    // maxLength:maxLength(1), 
                },
                header_row: { 
                    required, 
                    numeric, 
                    // minValue:minValue(1), 
                    // maxValue:checkMaxValue('printout_source')
                },

                detail_column: { 
                    required: requiredIf(function() {
                        return this.form.has_details;
                    }), 
                    numeric, 
                    // minValue:minValue(1), 
                    // maxValue:maxValue(9),
                    // maxLength:maxLength(1), 
                },

                footer_column: { 
                    required, 
                    numeric, 
                    // minValue:minValue(1), 
                    // maxValue:maxValue(9),
                    // maxLength:maxLength(1), 
                },
                footer_row: { 
                    required, 
                    numeric, 
                    // minValue:minValue(1), 
                    // maxValue:maxValue(9),
                    // maxLength:maxLength(1), 
                },
            },

            header_footer: {
                usage: { 
                    required, 
                },
                column: { 
                    required: requiredIf(function () {
                        return this.header_footer.usage
                    }), 
                    minValue:minValue(1), 
                    numeric,
                    maxValue(value) {
                        if(this.header_footer.usage == 'header') {
                            return Number(this.form.header_column) >= Number(value) 
                        } else {
                            return Number(this.form.footer_column) >= Number(value) 
                        }
                    },
                },
                row: { 
                    required: requiredIf(function () {
                        return this.header_footer.usage
                    }), 
                    minValue:minValue(1), 
                    numeric, 
                    maxValue(value) {
                        if(this.header_footer.usage == 'header') {
                            return Number(this.form.header_row) >= Number(value) 
                        } else {
                            return Number(this.form.footer_row) >= Number(value) 
                        }
                    },
                },

            },

            detail: {
                column: { 
                    required, 
                    numeric, 
                    // minValue:minValue(1), 
                    maxValue(value) {
                        return Number(this.form.detail_column) >= Number(value) 
                    }
                },
            },

            aggregate: {
                usage: { 
                    required, 
                },
                column: { 
                    required: requiredIf(function () {
                        return this.aggregate.usage
                    }), 
                    minValue:minValue(1), 
                    numeric,
                    maxValue(value) {
                        if(this.aggregate.usage == 'header') {
                            return Number(this.form.header_column) >= Number(value) 
                        } else {
                            return Number(this.form.footer_column) >= Number(value) 
                        }
                    },
                },
                row: { 
                    required: requiredIf(function () {
                        return this.aggregate.usage
                    }), 
                    minValue:minValue(1), 
                    numeric, 
                    maxValue(value) {
                        if(this.aggregate.usage == 'header') {
                            return Number(this.form.header_row) >= Number(value) 
                        } else {
                            return Number(this.form.footer_row) >= Number(value) 
                        }
                    },
                },
            },

        // }
    },
    computed: {
        header_footer_field_fields: function () {
            let custom = [];

            if(this.current_tab == 1) {
                custom.push({key: 'type', _style:'width:10%'})
            }

            custom.push(
                {key: 'display_name', label: 'Field',  _style:'width:20%'},
            );
            
            // if(this.current_tab == 1) {
                custom.push(
                    {key: 'field_custom_name', label: 'Custom Name', _style:'width:20%'},
                );
            // }

            custom.push(
                {key: 'usage', _style:'width:20%'},
            );

            if(this.current_tab == 1) {
                if(!custom.includes({key: 'source_type', },)) { 
                    custom.push(
                        {key: 'source_type', },
                        {key: 'validation',},
                        {key: 'readonly', _style:'width:1%' },
                    )
                }
            } else if(this.current_tab == 3)  {
                if(!custom.includes({key: 'aggregate_function', _style:'width:20%'})) {
                    custom.push(
                        {key: 'aggregate_function', _style:'width:20%'},
                        {key: 'aggregate_column_field_id', label:'Aggregate Column', _style:'width:20%' },
                    )
                }
            }
            custom.push(
                {key: 'column', _style:'width:1%' },
                {key: 'row', _style:'width:1%' },
            )

            if(this.edit) custom.push({key: 'action', _style:'width:1%', sorter: false})

            return custom;

            // let row = [
            //     {key: 'type', _style:'width:10%'},
            //     {key: 'display_name', _style:'width:20%'},
            //     {key: 'source_type', },
            //     {key: 'usage', _style:'width:20%'},
            //     {key: 'validation',   },
            //     {key: 'column', _style:'width:1%' },
            //     {key: 'row', _style:'width:1%' },
            //     // {key: 'is_reference', _style:'width:8%' },
            // ];

            // if(this.edit) row.push({key: 'action', _style:'width:1%', sorter: false});

            // return row;
        },
        detail_field_fields: function () {
            let row = [
                {key: 'type', _style:'width:10%'},
                {key: 'display_name', label: 'Field', _style:'width:20%'},
                {key: 'field_custom_name', label: 'Custom Name', _style:'width:20%'},
                {key: 'source_type', },
                {key: 'validation',   },
                {key: 'readonly', _style:'width:1%' },
                {key: 'column', _style:'width:1%' },
            ];

            if(this.edit) row.push({key: 'action', _style:'width:1%', sorter: false});

            return row;
        },
        
        custom_fields: function () {
            let c_fields = JSON.parse(JSON.stringify(this.fields))
            let custom;
            if(this.current_tab != 3) {
                custom = c_fields.filter(f => f.parameter_name != 'aggregate_function');
            } else {
                custom = c_fields.filter((f) => {
                    if(f.parameter_name == 'aggregate_function' || f.parameter_name == 'empty_block') {
                        return f;
                    }
                });
            }
            return custom;
        },

        isFormValid () { return !this.$v.form.$invalid },
        isFormDirty () { return this.$v.form.$anyDirty },

        
        isHeaderFooterValid () { return !this.$v.header_footer.$invalid },
        isHeaderFooterDirty () { return this.$v.header_footer.$anyDirty },

        
        isAggregateValid () { return !this.$v.aggregate.$invalid },
        isAggregateDirty () { return this.$v.aggregate.$anyDirty },

        
        isDetailValid () { return !this.$v.detail.$invalid },
        isDetailDirty () { return this.$v.detail.$anyDirty },
    },
    methods: {
        getPrintoutSource: function() {
            axios.get('/form-managements/printout-source/active-list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        this.printout_list.push(response.data.data[i].source)
                    }
                }
            })
        },
        getPrefixList: function() {
            axios.get('/form-managements/prefix/active-list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        this.prefix_list.push(response.data.data[i].prefix)
                    }
                }
            })
        },
        archive: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to archive the <strong>${this.form.name}<strong>`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/form-managements/form/archive/' + this.$route.params.id, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${this.form.name} successfully archived.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            this.form.status = 'Inactive'
                            this.toggleEdit()
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })

            // this.$swal({
            //     icon: "warning",
            //     title: "Are you sure?",
            //     html: `You are trying to archive the <strong>${this.form.name}<strong>`,
            //     showCancelButton: true,
            //     confirmButtonColor: "#DD6B55",
            //     confirmButtonText: "Yes",
            //     }).then((result) => {
            //     if(result.value){
            //         this.$Progress.start()
            //         axios.post('/form-managements/form/archive/' + this.$route.params.id, {validateStatus: () => true})
            //         .then(response => {
            //             if(response.status == 200) {
            //                 this.$swal({
            //                     toast: true,
            //                     position: 'top-right',
            //                     timer: 3000,
            //                     icon: 'success',
            //                     html: `${this.form.name} successfully archived.`,
            //                     showConfirmButton: false,
            //                     timerProgressBar: true,
            //                 })
            //                 this.form.status = 'Inactive'
            //                 this.toggleEdit()
            //                 this.$Progress.finish()
            //             }
            //         })
            //     }else{
            //         this.$swal('Cancelled!','','error');
            //     }
            // })
        },
        restoreForm: function () {
            this.$Progress.start()
            axios.post('/form-managements/form/restore/' + this.$route.params.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${this.form.name} successfully restored.`,
                        timerProgressBar:true,
                    })
                    this.view_key++;
                    this.form.status = 'Active'
                    this.$Progress.finish();
                }
            })
        },
        previewForm: function () {
            this.form_review = true;
            this.$nextTick(() => {
                this.$refs.formPreview.form.footer_column = this.form.footer_column;
                this.$refs.formPreview.form.header_column = this.form.header_column;

                // console.log(this.aggregate_field);
                
                let header_footer_aggregate = [...this.header_footer_field, ...this.aggregate_field];

                let _header =  FormManagementJS.filterObjectFromArray(header_footer_aggregate, 'usage', 'header');

                let _footer = FormManagementJS.filterObjectFromArray(header_footer_aggregate, 'usage', 'footer');



                this.$refs.formPreview.headerFieldsComps = FormManagementJS.generateHeaderFooterField(_header, 'header');
                this.$refs.formPreview.detail_field = FormManagementJS.sortDetailFields(this.detail_field);
                this.$refs.formPreview.footerFieldsComps = FormManagementJS.generateHeaderFooterField(_footer, 'footer');
            })
        },
        addAggregateToHeaderFooter: function () {
            let field = {
                random_id: Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100) + 1,
                field_id: null,
                id: null,
                readonly: false,
                validation: null,
                // field_id: this.aggregate.field_id,
                type: this.aggregate.field_type,
                display_name: this.aggregate.field_display_name,
                field_custom_name: null,
                usage: this.aggregate.usage,
                column: this.aggregate.column,
                row: this.aggregate.row,
                aggregate_field: true,
                aggregate_function: this.aggregate.function ? this.aggregate.function.value : null,
                aggregate_column: this.aggregate.aggregate_field_id ? this.aggregate.aggregate_field_id.parameter_name : null,
                aggregate_column_field_id: this.aggregate.aggregate_field_id ? this.aggregate.aggregate_field_id.field_id : null,
                label_format: null,
                label_value: null,
            }


            if(this.header_footer_field.some(el => el.column === field.column && el.usage === field.usage && el.row === field.row)) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'The same usage, column, and row is already exists in the Header & Footer Or Aggregate Function Lists.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }
            this.drpdwn_field_aggregate = ''
            this.drpdwn_usage_aggregate = ''
            this.aggregate = this.clearAggregateField();
            this.aggregate_field.push(field);
        },
        clearAggregateField: function () {
            return {
                // field_id: null,
                field: null,
                field_display_name: null,
                field_type:null,
                field_source_type: null,
                function: null,
                usage: null,
                column: null,
                row: null,
                aggregate_field_id:null,
                label_format: null,
                label_value: null,
                // is_reference: false,
            }
        },
        addFieldToHeaderFooter: function () {
            let field = {
                id:null,
                readonly: this.header_footer.field_read_only,
                random_id: Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100) + 1,
                field_id: this.header_footer.field_id,
                type: this.header_footer.field_type,
                display_name: this.header_footer.field_display_name,
                source_type: this.header_footer.field_source_type,
                usage: this.header_footer.usage,
                validation: this.header_footer.field_validation,
                field_custom_name: this.header_footer.field_custom_name,
                column: this.header_footer.column,
                row: this.header_footer.row,
                hf_field_value_count: "0",
                
                aggregate_field: false,
                aggregate_function: null,
                aggregate_column: null,
                aggregate_column_field_id: null,

                label_format: this.header_footer.label_format,
                label_value: this.header_footer.label_value,
            }

            if(this.header_footer_field.some(el => el.column === field.column && el.usage === field.usage && el.row === field.row)) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'The same usage, column, and row is already exists in the list.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }
            if(field.display_name != 'Empty Block' && field.type != 'label') {
                if(this.header_footer_field.some(el => el.display_name === field.display_name)) {
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'error',
                        title: `${field.display_name} is already exists in the list.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                    // return this.$swal({
                    //     icon: "warning",
                    //     title: "Field already exists!",
                    //     html: `Seems that <b>${field.display_name}</b> already exists in the list, would you still like to continue?`,
                    //     showCancelButton: true,
                    //     confirmButtonColor: "#DD6B55",
                    //     confirmButtonText: "Yes",
                    // }).then(result => {
                    //     if(result.value) {

                    //         this.drpdwn_field_header_footer = ''
                    //         this.drpdwn_usage_header_footer = ''
                    //         this.header_footer = this.clearHeaderFooterField();
                    //         this.header_footer_field.push(field);
                    //     }
                    // })
                }
            }
            
            
            // if(field.is_reference) {
            //     this.selected_reference = field.random_id
            // }

            this.drpdwn_field_header_footer = ''
            this.drpdwn_usage_header_footer = ''
            this.header_footer = this.clearHeaderFooterField();
            this.header_footer_field.push(field);
        },
        clearHeaderFooterField: function () {
            return {
                field_id: null,
                field_display_name: null,
                field_custom_name: null,
                field_type:null,
                field_validation: null,
                field_source: null,
                field_source_type: null,
                field_read_only: false,
                usage: null,
                column: null,
                row: null,
                label_format: null,
                label_value: null,
                // is_reference: false,
            }
        },
        addFieldToDetail: function (){
            let field = {
                id:null,
                readonly: this.detail.field_read_only,
                field_id: this.detail.field_id,
                type: this.detail.field_type,
                display_name: this.detail.field_display_name,
                parameter_name: this.detail.paramenter_name,
                source_type: this.detail.field_source_type,
                source: this.detail.field_source,
                validation: this.detail.field_validation,
                field_custom_name: this.detail.field_custom_name,
                column: this.detail.column,
                d_field_value_count: "0",
                label_format: this.detail.label_format,
                label_value: this.detail.label_value,
            }
            if(this.detail_field.some(el => el.column === field.column)) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Column is already exists in the list.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }

            if(field.display_name != 'Empty Block' && field.type != 'label') {
                if(this.detail_field.some(el => el.display_name === field.display_name)) {
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'error',
                        title: `${field.display_name} is already exists in the list.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
            }
            this.drpdwn_field_detail = ''
            this.detail = this.clearDetailField();
            this.detail_field.push(field);


            this.detail_field.sort(function (x, y) {
                return x.column - y.column;
            });
        },
        clearDetailField: function () {
            return {
                field_id: null,
                field_display_name: null,
                field_custom_name: null,
                field_type:null,
                field_read_only: false,
                field_validation: null,
                field_source: null,
                field_source_type: null,
                column: null,
                paramenter_name: null,
                label_format: null,
                label_value: null,
            }
        },
        removeFieldToTable: function(i, item, array) {
            switch(array) {
                case 'header_footer_field' : 
                    this.header_footer_field.splice(i, 1);
                    if(item.id != null) this.recently_removed_header_footer_id.push(item.id);
                    break;
                case 'detail_field':
                    this.detail_field.splice(i, 1);
                    if(item.id != null) this.recently_removed_detail_id.push(item.id);
                    break;
                default:
                    this.aggregate_field.splice(i, 1);
                    if(item.id != null) this.recently_removed_header_footer_id.push(item.id);
                    break;
            }
        },
        getForm: function() {
            axios.get('/form-managements/form/'+this.$route.params.id, {validateStatus: () => true}).then(response => {
                if(response.status == 200)  {
                    this.assignData(response.data.data);
                }
            })
        },
        assignData: function (data){
            this.data_backup = JSON.parse(JSON.stringify(data))

            this.total_fields_with_value = 0

            this.recently_removed_header_footer_id = [];
            this.recently_removed_detail_id = [];
            
            this.aggregate_field = [];
            this.header_footer_field = [];
            this.detail_field = [];

            this.form.name= '',
            this.form.printout_source= '',
            this.form.description= '',
            this.form.header_column= null,
            this.form.header_row= null,
            this.form.detail_column= null,
            this.form.footer_column= null,
            this.form.footer_row= null
            

            // this.form = [];
            // this.form_loaded = false;

            let header_footer_aggregate = JSON.parse(JSON.stringify(data.form_header_footer_fields))


            let fhff = header_footer_aggregate.filter(obj => {return obj.aggregate_field != "1"}); // form header footer fields
            let fhaf = header_footer_aggregate.filter(obj => {return obj.aggregate_field == "1"}); // form aggregate fields

            let _header =  FormManagementJS.filterObjectFromArray(fhff, 'usage', 'header');
            let _footer = FormManagementJS.filterObjectFromArray(fhff, 'usage', 'footer');

            let _aggregateHeader = FormManagementJS.filterObjectFromArray(fhaf, 'usage', 'header');
            let _aggregateFooter = FormManagementJS.filterObjectFromArray(fhaf, 'usage', 'footer');


            let _sortedHeader = FormManagementJS.sortHeaderFooterFields(_header);
            let _sortedFooter = FormManagementJS.sortHeaderFooterFields(_footer);

            let _aggregateSortedHeader = FormManagementJS.sortHeaderFooterFields(_aggregateHeader);
            let _aggregateSortedFooter = FormManagementJS.sortHeaderFooterFields(_aggregateFooter);
  
            let _fixedHeader = FormManagementJS.fixedHeaderFooterFieldsArray(_sortedHeader);
            let _fixedFooter = FormManagementJS.fixedHeaderFooterFieldsArray(_sortedFooter);

            
            let _aggregateFixedHeader = FormManagementJS.fixedHeaderFooterFieldsArray(_aggregateSortedHeader);
            let _aggregateFixedFooter = FormManagementJS.fixedHeaderFooterFieldsArray(_aggregateSortedFooter);
            
            this.header_footer_field = [..._fixedHeader, ..._fixedFooter];

            this.aggregate_field = [ ..._aggregateFixedHeader, ..._aggregateFixedFooter];
            

            let over_all_hf_field_value_count=0;
            let over_all_d_field_value_count=0;

            this.header_footer_field.forEach(item => {
                over_all_hf_field_value_count += parseInt(item.hf_field_value_count);
            });

            this.detail_field = FormManagementJS.fixedDetailFieldsArray(FormManagementJS.sortDetailFields(data.form_detail_fields));
            
            this.detail_field.forEach(item => {
                over_all_d_field_value_count += parseInt(item.d_field_value_count);
            });

            this.total_fields_with_value = over_all_hf_field_value_count + over_all_d_field_value_count;

            delete data.form_header_footer_fields;
            delete data.form_detail_fields;

            this.form = data;
            this.form.has_details = data.has_details == "1" ? true : false;
            // this.form.external_use = data.external_use == "1" ? 1 : 0;
            this.form.status = data.deleted_at ? 'Inactive' : 'Active';
            this.form_loaded = true;
            this.$Progress.finish();
        },
        invalidFeedbackForColumn: function ($v, field_category) {
            if(field_category == 'header_footer' ? !$v.header_footer.column.numeric : !$v.aggregate.column.numeric) return 'Only accept numbers';  
            if(field_category == 'header_footer' ? this.header_footer.usage == 'header' : this.aggregate.usage == 'header') {
                if(field_category == 'header_footer' ? !$v.header_footer.column.maxValue : !$v.aggregate.column.maxValue) return 'Cannot be greater than Form\'s header column';  
            } else {
                if(field_category == 'header_footer' ? !$v.header_footer.column.maxValue : !$v.aggregate.column.maxValue) return 'Cannot be greater than Form\'s footer column';  
            }
            if(field_category == 'header_footer' ? !$v.header_footer.column.required : !$v.aggregate.column.required) return ''; 
        },
        invalidFeedbackForRow: function ($v, field_category) {
            if(field_category == 'header_footer' ? !$v.header_footer.row.numeric : !$v.aggregate.row.numeric) return 'Only accept numbers';  
            if(field_category == 'header_footer' ? this.header_footer.usage == 'header' : this.aggregate.usage == 'header') {
                if(field_category == 'header_footer' ? !$v.header_footer.row.maxValue : !$v.aggregate.row.maxValue) return 'Cannot be greater than Form\'s header row';  
            } else {
                if(field_category == 'header_footer' ? !$v.header_footer.row.maxValue : !$v.aggregate.row.maxValue) return 'Cannot be greater than Form\'s footer row';  
            }
            if(field_category == 'header_footer' ? !$v.header_footer.row.required : !$v.aggregate.row.required) return ''; 
        },
        getFields: function() {
            axios.get('/form-managements/field/active-list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) this.fields = response.data.data
                let empty_block = {
                    id: null,
                    display_name: 'Empty Block',
                    parameter_name: 'empty_block',
                    field_type: {
                        name: 'n/a'
                    },
                    source_type: 'none',
                    validation: null
                }
                this.fields.push(empty_block)
            })
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2

            if(this.edit) {
                this.$v.form.$touch();
                this.$v.header_footer.$touch();
                this.$v.detail.$touch();
                this.$v.aggregate.$touch();
            }
        },
        changeTab: function (item) {
            this.current_tab = item;
        },
        returnInformation: function () {
            this.view_key++;
            this.assignData(JSON.parse(JSON.stringify(this.data_backup)))
        },
        selectChangeUsage: function (item, index) {
            this.header_footer_field[index].usage = item.usage.name
        },
        selectChangeDisplayName:function (theArray, params, item, index) {

            theArray[index].field_id = item.display_name.id
            theArray[index].type = item.display_name.field_type.name

            if(params == 'header_footer') {
                theArray[index].hf_field_value_count = 0;
            } else {
                theArray[index].d_field_value_count = 0;                
            }

            theArray[index].validation = item.display_name.validation
            theArray[index].source_type = item.display_name.source_type
            theArray[index].source = item.display_name.source
            
            theArray[index].display_name = item.display_name.display_name
        },
        updateForm: function () {   
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to update a form.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    let hf_field=[];
                    let d_field=[];
                    
                    let header_footer_aggregate = [...this.header_footer_field, ...this.aggregate_field];

                    for (let index = 0; index < header_footer_aggregate.length; index++) {
                        const element = header_footer_aggregate[index];
                        let row = {
                            id: element.id,
                            form_id: this.$route.params.id,
                            field_id: element.field_id,
                            column: element.column,
                            row: element.row,
                            usage: element.usage,
                            validation: element.validation,
                            field_custom_name: element.field_custom_name,
                            readonly: element.readonly,
                            aggregate_field: element.aggregate_field,
                            aggregate_column_field_id: element.aggregate_column_field_id,
                            aggregate_function: element.aggregate_function,
                        }
                        hf_field.push(row);
                    }

                    for (let index = 0; index < this.detail_field.length; index++) {
                        const element = this.detail_field[index];
                        let row = {
                            id: element.id,
                            form_id: this.$route.params.id,
                            field_id: element.field_id,
                            column: element.column,
                            field_custom_name: element.field_custom_name,
                            readonly: element.readonly,
                            validation: element.validation,
                        }
                        d_field.push(row);
                    }

                    let data = {
                        form: this.form,
                        header_footer: hf_field,
                        detail: d_field,
                    }

                    if(this.recently_removed_header_footer_id.length > 0) {
                        data.recently_removed_header_footer_id =  this.recently_removed_header_footer_id
                    }

                    if(this.recently_removed_detail_id.length > 0) {
                        data.recently_removed_detail_id =  this.recently_removed_detail_id
                    }

                    this.$Progress.start()
                    return axios.post('/form-managements/form/update/'+this.$route.params.id, data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.form.name}</b> has been updated.`,
                            })
                            // this.form = this.clearForm();
                            // this.header_footer_field = []
                            // this.detail_field = []
                            
                            // this.$v.form.$reset()
                            // this.$v.header_footer.$reset()
                            // this.$v.detail.$reset()

                            // this.$v.form.$touch();
                            // this.$v.header_footer.$touch();
                            // this.$v.detail.$touch();
                            // this.getForm();
                            this.assignData(response.data.data)
                            this.toggleEdit();
                            
                            this.view_key++;

                            this.form_review = false;

                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        }
    },
    watch: {
        modal_new_field: function (value) {
            if(!value) this.getFields();
        },
        drpdwn_field_header_footer: function (value) {
            this.header_footer.field_id = null;
            this.header_footer.field = null;
            this.header_footer.field_display_name = null;
            this.header_footer.field_type = null
            this.header_footer.field_validation = null;
            this.header_footer.field_source = null;
            this.header_footer.field_source_type = null;
            this.header_footer.drop_down_value = null
            this.header_footer.label_value = null;
            this.header_footer.label_format = null;
            if(value) {
                this.header_footer.field_id = value.id
                this.header_footer.field = value.display_name
                this.header_footer.field_display_name = value.display_name;
                this.header_footer.field_type = value.field_type.name ;
                this.header_footer.field_validation = value.validation;
                if(value.source_type == 'api') this.header_footer.field_source = value.source;
                if(value.field_drop_down_values_data && value.field_drop_down_values_data.length > 0) this.header_footer.drop_down_value = value.field_drop_down_values_data.map((i) => {return i.value})
                this.header_footer.field_source_type = value.source_type;
                this.header_footer.label_value = value.label_value ? value.label_value : null;
                this.header_footer.label_format = value.label_format ? value.label_format : null;
            }
        },
        drpdwn_usage_header_footer: function (value) {
            this.header_footer.usage = null;
            if(value) this.header_footer.usage = value.name
        },
        drpdwn_field_detail: function (value) {
            this.detail.field_id = null;
            this.detail.field = null;
            this.detail.field_display_name = null;
            this.detail.field_type = null
            this.detail.field_source = null;
            this.detail.field_validation = null;
            this.detail.field_source_type = null;
            this.detail.paramenter_
            name = null;
            this.detail.drop_down_value = null
            this.detail.label_value = null;
            this.detail.label_format = null;
            if(value) {
                this.detail.field = value.display_name
                this.detail.field_id = value.id
                this.detail.field_display_name = value.display_name;
                this.detail.field_type = value.field_type.name;
                this.detail.field_validation = value.validation;
                if(value.source_type == 'api') this.detail.field_source = value.source;
                if(value.field_drop_down_values_data && value.field_drop_down_values_data.length > 0) this.detail.drop_down_value = value.field_drop_down_values_data.map((i) => {return i.value})
                this.detail.field_source_type = value.source_type;
                this.detail.paramenter_name = value.parameter_name
                this.detail.label_value = value.label_value ? value.label_value : null;
                this.detail.label_format = value.label_format ? value.label_format : null;
            } 
        },
        drpdwn_field_aggregate: function (value) {
            // this.aggregate.field_id = null;
            this.aggregate.field = null;
            this.aggregate.field_display_name = null;
            this.aggregate.function = null;
            this.aggregate.aggregate_field_id = null;
            if(value) {
                // this.aggregate.field_id = value.id
                this.aggregate.field = value.display_name
                this.aggregate.field_display_name = value.display_name;
            }
        },
        drpdwn_usage_aggregate: function (value) {
            this.aggregate.usage = null;
            if(value) this.aggregate.usage = value.name
        },
        'form.has_details': function (val) {
            this.form.detail_column = val ? null : 0
            this.detail_field = []
        }

    }
}
</script>